import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Typography,
  Box,
  Container,
  TextField,
  CssBaseline,
  Button,
  Avatar,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
  Paper,
  FormControl,
  Tabs,
  Tab,
  Card,
  CardContent
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { connect } from "react-redux";
import { loginIMSAdmin, signUp } from "../../app/store/actions/authActions";
import {
  Navigate,
  NavLink,
  Route,
  Routes,
  useNavigate,
  useParams
} from "react-router-dom";
import {
  centered_flex_box,
  MainInput,
  MainInputLabel,
  MainPassword,
  main_button,
  StyledInput,
  MainPhone,
  sec_button,
  left_flex_box
} from "../../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Image, Table, notification } from "antd";
import Logo from "../../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import ImageComponent from "../../app/components/ImageComponent";
import { getProducts, getWarehouses, receiveInbound } from "../../app/store/actions/imsAdminActions";
import { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import moment from "moment/moment";
import InboundsTable from "../../app/components/InboundsTable";
import { useState } from "react";
import mongoose from "mongoose";
import { getRequest } from "../../core/network";
import QrReader from "modern-react-qr-reader"
import { isNumber } from "lodash";

export const ReceiveInbounds = ({
  getProducts,
  getWarehouses,
  receiveInbound,
  user,
  token,
  warehouse,
  products,
  isLoading
}) => {
  const navigate = useNavigate();
  const { warehouseId } = useParams();
  const [inboundItemText, setInboundItemText] = useState("");
  const [inboundItemData, setInboundItemData] = useState({});
  const [inboundItems, setInboundItems] = useState({});
  const [gettingItem, setGettingItem] = useState(false);
  const [scanning, setScanning] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [shippingServiceToScan, setShippingServiceToScan] = useState("Bosta")

  useEffect(() => {
    getWarehouses({ warehouseId })
    setSubmitted(false)
  }, [warehouseId])

  useEffect(() => {
    if(Object.keys(inboundItemData).length > 0) {
      console.log("InboundItemData: ",inboundItemData)
      if (mongoose.Types.ObjectId.isValid(inboundItemData.inboundItemOrOrderId)) {
        return handleGetInboundItem()
      }
      if (inboundItemData.inboundItemOrOrderId) {
        console.log("By Order")
        return handleGetInboundItem()
      }
    }
  }, [inboundItemData])

  const handleParseInput = (value) => {
    setInboundItemText(value)

    // Try parsing as JSON first
    try {
      const json = JSON.parse(value);
      console.log("Parsed JSON:", json);
      if(isNumber(json)) {
        throw new Error()
      }
      setInboundItemData({
        uuid: json.uuid,
        inboundItemOrOrderId: json.inboundItemId,
      });
      setInboundItemText("")
      return; // Exit function if JSON parsing is successful
    } catch (err) {
      console.log("Failed to parse as JSON");
    }

    if(warehouse?.name === "Faulty Warehouse") {
      if(shippingServiceToScan === "Shipblu") {
        const shipBluIdParts = value.split("-");
        if (shipBluIdParts.length >= 2 && shipBluIdParts[1].length >= 7 && !value.includes("{") && !value.includes("}")) {
          console.log("Parsed QR Code:", shipBluIdParts[1]);
          setInboundItemText("")
          setInboundItemData({ inboundItemOrOrderId: shipBluIdParts[1] });
        }
      } else if(shippingServiceToScan === "Bosta"){
        if (value.length >= 8) {
          console.log("Parsed QR Code:", value);
          setInboundItemText("")
          setInboundItemData({ inboundItemOrOrderId: value });
        }
      }
    }

    // Try decoding as ASCII string if JSON fails
    // try {
    //   console.log("Attempting to decode ASCII...");
    //   const fixedAscii = value.replace(/\s/g, "");
    //   const asciiCodes = fixedAscii.match(/.{1,3}/g); // Split into groups of 3 digits
    //   const decodedString = asciiCodes.map(code => String.fromCharCode(parseInt(code, 10))).join("");
    //   console.log("Decoded ASCII string:", decodedString);
      
    //   const json = JSON.parse(decodedString); // Try parsing the decoded string as JSON
    //   console.log("Parsed decoded JSON:", json);
    //   setInboundItemData({
    //     uuid: json.uuid,
    //     inboundItemOrOrderId: json.inboundItemId,
    //   });
    //   setInboundItemText("")
    //   return; // Exit function if ASCII decoding and JSON parsing are successful
    // } catch (err) {
    //   console.log("Failed to decode ASCII or parse decoded string as JSON");
    // }
  };

  const handleGetInboundItem = () => {
    console.log("handleGetInboundItem Called")
    setGettingItem(true)
    getRequest({ inboundItemId: inboundItemData.inboundItemOrOrderId, uuid: inboundItemData.uuid, warehouseId }, undefined, token, "IMAdmin/inbounds/items").then((response) => {
      const { data } = response
      const { item, items } = data;
      let temp = inboundItems
      if(item) {
        if (Object.keys(temp).includes(item._id)) {
          if(temp[item._id].uuids.includes(inboundItemData.uuid)){
            return notification.error({message: "Exact Item Already Scanned", style: { marginTop: "10vh" }})
          } else {
            temp[item._id].quantityReceived = temp[item._id].quantityReceived + 1
            temp[item._id].uuids.push(inboundItemData.uuid)
          }
        } else {
          temp[item._id] = { quantityReceived: 1, _id: item._id, product: item.product, faulty: false, uuids: [inboundItemData.uuid] }
        }
      } else {
        items.map((inboundItem) => {
          if (Object.keys(temp).includes(inboundItem._id)) {
            temp[inboundItem._id].quantityReceived = temp[inboundItem._id].quantityReceived + 1
          } else {
            temp[inboundItem._id] = { quantityReceived: 1, _id: inboundItem._id, product: inboundItem.product, faulty: false }
          }
        })
      }
      notification.success({message: item ? "Fetched Item" : "Fetched Items", style: { marginTop: "10vh" } })
      console.log(temp)
      setInboundItems(temp)
    }).catch((err) => { console.error(err); notification.error({message: err.response?.data?.message || "Product Not Found", style: { marginTop: "10vh" } }) }).finally(() => {
      setGettingItem(false);
      setInboundItemData({});
    })
  }

  const updateItemQuantity = (quantity, id) => {
    let temp = {}
    Object.assign(temp, inboundItems)
    temp[id].quantityReceived = quantity
    setInboundItems(temp)
    console.log(inboundItems)
  }

  const updateItemFaultiness = (faulty, id) => {
    let temp = {}
    Object.assign(temp, inboundItems)
    temp[id].faulty = faulty
    setInboundItems(temp)
    console.log(inboundItems)
  }

  const handleScan = (data) => {
    if(!scanning && !gettingItem && data) {
      setScanning(true)
      console.log(data)
      handleParseInput(data);
    }
  }

  const handleSubmit = () => {
    receiveInbound({ itemUpdates: Object.values(inboundItems) }, navigate)
    setSubmitted(true)
  }

  return (
    <div>
      <Box sx={{ width: "100%", padding: 5, mt: isMobile ? 0 : 2 }}>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Receive Inbound Items</Typography>
          {/* <IconButton onClick={() => navigate("/ims/admin/orders/add")} sx={{ color: "var(--secColor)", mx: 2 }}><AddCircleOutline /></IconButton> */}
        </Box>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Card sx={{ width: "100%" }}>
            {isMobile ? (<CardContent>
              <Box sx={{ padding: 1, ...centered_flex_box, flexDirection: "column" }}>
                  <QrReader
                      onScan={handleScan}
                      style={{
                          height: 250,
                          width: 250,
                      }}
                      facingMode={"environment"}
                  />
                  <Button onClick={() => setScanning(false)} disabled={!scanning} sx={{ ...main_button, mt:1 }}>Scan Again</Button>
              </Box>
            </CardContent>) : (<CardContent>
              <Box sx={{ ...left_flex_box }}>
                {warehouse?.name === "Faulty Warehouse" && <MainInput
                  select
                  sx={{ marginInlineEnd: 2, minWidth: "20vw" }}
                  defaultValue={shippingServiceToScan}
                  label="Shipping AWB"
                  onChange={({ target }) => setShippingServiceToScan((target.value))}
                >
                  <MenuItem value={"Shipblu"}>ShipBlu</MenuItem>
                  <MenuItem value={"Bosta"}>Bosta</MenuItem>
                </MainInput>}
                <MainInput
                  sx={{ marginInlineEnd: 2, minWidth: "50vw" }}
                  focused
                  value={inboundItemText}
                  label="Item or Order Id"
                  onChange={({ target }) => handleParseInput((target.value))}
                />
                <Button onClick={() => handleGetInboundItem()} sx={{ ...main_button, mt:1 }}>Get Items</Button>
              </Box>
            </CardContent>)}
          </Card>
        </Box>
        <Box>
          {Object.values(inboundItems)?.map((item) => {
            return (
              <Card key={item._id} sx={{ mt: 1 }}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={2}>
                      <Avatar src={item.product.image} />
                    </Grid>
                    <Grid item xs={6} sx={{ ...centered_flex_box }}>
                      <Typography>{item.product.name}</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ ...centered_flex_box }}>
                      <MainInput
                        value={item.faulty}
                        label="Product State"
                        onChange={({ target }) => updateItemFaultiness(target.value, item._id)}
                        fullWidth
                        select
                      >
                        <MenuItem value={true}>Faulty</MenuItem>
                        <MenuItem value={false}>Ok</MenuItem>
                      </MainInput>
                    </Grid>
                    <Grid item xs={2} sx={{ ...centered_flex_box }}>
                      <MainInput
                        value={item.quantityReceived}
                        label="Quantity Received"
                        onChange={({ target }) => updateItemQuantity(parseInt(target.value), item._id)}
                        type="number"
                        inputProps={{
                          min: 1
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )
          })}
        </Box>
        <Box sx={{ ...centered_flex_box, mt: 3 }}>
          <Button onClick={handleSubmit} disabled={isLoading || submitted} sx={{ ...main_button }}>{isLoading ? <CircularProgress color="white" /> : "Receive All"}</Button>
        </Box>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  products: state?.records?.products,
  warehouse: state?.records?.warehouse
});

const mapDispatchToProps = { getProducts, getWarehouses, receiveInbound };

export default connect(mapStateToProps, mapDispatchToProps)(ReceiveInbounds);
