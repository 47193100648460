import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Card, Grid, CardContent, Dialog } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { centered_flex_box, main_button, MainInput } from '../../app/components/Styles';
import { useEffect } from 'react';
import { } from '../../app/store/actions/imsAdminActions';
import ImageComponent from '../../app/components/ImageComponent';
import { getOrders, assignOrder } from '../../app/store/actions/imsEmployeesActions';
import moment from 'moment';
import { Inventory, More, MoreHoriz } from '@mui/icons-material';
import { useState } from 'react';
import QrReader from "modern-react-qr-reader"
import { isDesktop, isMobile } from 'react-device-detect';

export const IMSEmployeeHome = ({ user, getOrders, assignOrder, order }) => {

    const navigate = useNavigate()
    const [shipbluId, setShipbluId] = useState(null);
    const [bostaTrackingId, setBostaTrackingId] = useState(null);
    const [scanning, setScanning] = useState(false);
    const [scanText, setScanText] = useState("");
    const [shippingServiceToScan, setShippingServiceToScan] = useState("Bosta")

    useEffect(() => {
        getOrders({ shipbluId })
    }, [shipbluId])

    useEffect(() => {
        getOrders({ bostaTrackingId })
    }, [bostaTrackingId])

    const startOrder = (order) => {
        if (order.assignee?._id === user._id) {
            navigate(`/ims/employee/order/${order._id}`)
        } else {
            assignOrder({ orderId: order._id }, navigate);
        }
    }

    const handleScan = (data) => {
        if (data) {
            if(isDesktop) {
                setScanText(data)
                const shipBluIdParts = data.split("-");
                if (shippingServiceToScan === "Shipblu" && shipBluIdParts.length >= 2 && shipBluIdParts[1].length >= 7) {
                    console.log("Parsed QR Code:", shipBluIdParts[1]);
                    setShipbluId(shipBluIdParts[1])
                    setScanning(false);
                }
                if (shippingServiceToScan === "Bosta" && shipBluIdParts.length < 2 && data.length >= 8){
                    setBostaTrackingId(data)
                    setScanning(false);
                }
            } else if(isMobile) {
                if(data.split("-").length > 1){
                    setShipbluId(data.split("-")[1])
                    setScanning(false);
                } else {
                    setBostaTrackingId(data)
                    setScanning(false);
                }
            }
        }
    }

    return (
        <div>
            <Box sx={{ ...centered_flex_box, m: 2, flexDirection: "column" }}>
                <Typography variant='h5' fontWeight={'bold'}>{user?.name} مرحباً</Typography>

                <Button onClick={() => setScanning(true)} sx={{ ...main_button, mt: 1 }}> ابدأ طلب </Button>

                <Dialog
                    open={scanning}
                    onClose={() => setScanning(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{ color: "var(--appBg)" }}
                >
                    <Box sx={{ padding: 1, ...centered_flex_box, flexDirection: "column" }}>
                        <Typography variant='h5' textAlign={"center"} fontWeight={'bold'}>امسح الكود الموجود على بوليصة الشحن لبدء الطلب</Typography>
                        <Typography>(QR Code)</Typography>
                        
                        {isMobile && <QrReader
                            onScan={handleScan}
                            style={{
                                height: 300,
                                width: 300,
                            }}
                            facingMode={"environment"}
                        />}
                        {isDesktop && <MainInput
                            select
                            sx={{ marginBottom: 5 }}
                            defaultValue={shippingServiceToScan}
                            label="شركة التوصيل"
                            onChange={({ target }) => setShippingServiceToScan((target.value))}
                            fullWidth
                        >
                            <MenuItem value={"Shipblu"}>ShipBlu</MenuItem>
                            <MenuItem value={"Bosta"}>Bosta</MenuItem>
                        </MainInput>}
                        {isDesktop && <MainInput
                            fullWidth
                            focused
                            value={scanText}
                            label="QR Code"
                            onChange={({ target }) => handleScan((target.value))}
                        />}
                        {isDesktop && <Button onClick={() => getOrders({ bostaTrackingId: scanText })} sx={{ ...main_button, mt:1 }}>Get Order</Button>}
                    </Box>
                </Dialog>

                {order &&
                    <Card sx={{ width: "100%", marginY: 1 }}>
                        <CardContent sx={{ ...centered_flex_box, flexDirection: "column", width: "100%" }}>
                            <Typography fontWeight={'bold'}>{order.status === "New" ? "طلب جديد" : (order.status === "Preparing" ? "طلب تحت التنفيذ" : "طلب جاهز")}</Typography>
                            {order.status === "New" ? <Typography>Created {moment(order.createdAt).fromNow()}</Typography> : <Typography>Assigned {moment(order.assignedAt).fromNow()}</Typography>}
                            <Typography>{order.externalId}</Typography>
                            {order.status === "New" && <Button sx={{ ...main_button }} onClick={() => startOrder(order)} ><Inventory sx={{ mx: 1 }} /> عين الطلب</Button>}
                            {order.status === "Preparing" && <Button sx={{ ...main_button }} onClick={() => navigate(`/ims/employee/order/${order._id}`)} ><Inventory sx={{ mx: 1 }} /> جهز الطلب</Button>}
                        </CardContent>
                    </Card>}
            </Box>
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    warehouses: state?.records?.warehouses,
    order: state?.records?.order
});

const mapDispatchToProps = { getOrders, assignOrder };

export default connect(mapStateToProps, mapDispatchToProps)(IMSEmployeeHome);
