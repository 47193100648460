import * as React from "react";
import {
    Modal,
    Typography,
    Box,
    Container,
    TextField,
    CssBaseline,
    Button,
    Avatar,
    Select,
    MenuItem,
    InputLabel,
    FormHelperText,
    FormControl,
    CircularProgress,
    Chip,
} from "@mui/material";
import { connect } from "react-redux";
import { notification } from "antd";
import {
    centered_flex_box,
    sec_button,
    reject_button,
    main_button,
    MainInput,
} from "../../app/components/Styles";
import ReactFileReader from "react-file-reader";
import Upload from "@mui/icons-material/Upload";
import Download from "@mui/icons-material/Download";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import download from "downloadjs";
import Papa, { parse } from "papaparse";
import { addTransactions } from "../../app/store/actions/whFinanceActions";
import { ReceiptLong } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';

export const AddTransactions = ({
    addTransactions,
    wait,
}) => {
    const [file, setFile] = React.useState(null);
    const [source, setSource] = React.useState("Bosta");
    const navigate = useNavigate()

    const handleDeleteFile = () => {
        setFile(null);
    };

    function timeout(delay) {
        return new Promise((res) => setTimeout(res, delay));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        var reader = new FileReader();
        if (file) {
            reader.readAsText(file);
            reader.onload = async () => {
                try {
                    const parsed = Papa.parse(reader.result, { header: true });
                    console.log(parsed.data)
                    const transactions = parsed.data.filter((transaction) => { return transaction.tracking_id !== "" });
                    addTransactions({ transactions }, navigate)
                } catch (err) {
                    console.log(err);
                }
            };
        } else {
            notification.error({ message: "Please Upload a valid .csv file" });
        }
    };

    const handleBostaSubmit = (event) => {
        event.preventDefault();
        var reader = new FileReader();
        if (file) {
            reader.onload = async (e) => {
                try {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];
                    const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });


                    // Assuming the first row is the header
                    const header = parsedData[0];
                    const transactions = []
                    parsedData.slice(1).map((row) => {
                        const transaction = {};
                        row.forEach((value, index) => {
                            transaction[header[index]] = value;
                        });

                        transactions.push({
                            "tracking_id": transaction["Order Id"],
                            "cod": transaction["COD"],
                            "cashoutId": transaction["Cash-out ID"]
                        })
                        transactions.push({
                            "tracking_id": transaction["Order Id"],
                            "fees": transaction["Total Fees"],
                            "service": transaction["Order Status"] === "DELIVERED" ? "DLV" : transaction["Order Status"] === "RETURNED" ? "RTN" : "NONE",
                            "cashoutId": transaction["Cash-out ID"]
                        });
                    })

                    console.log(transactions)
                    addTransactions({ transactions }, navigate);
                } catch (err) {
                    console.log(err);
                }
            };
            reader.readAsArrayBuffer(file);
        } else {
            notification.error({ message: "Please upload a valid Excel file (.xlsx or .xls)" });
        }
    };

    const handleFiles = (files) => {
        setFile(files[0]);
    };

    return (
        <Box sx={centered_flex_box}>
            {wait.isLoading && (
                <Box sx={{ ...centered_flex_box, flexDirection: "column", }} >
                    <CircularProgress sx={{ color: "var(--secColor)" }} />
                    <Typography fontWeight="bold">
                        Processing your request...
                    </Typography>
                </Box>
            )}
            {!wait.isLoading && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "var(--secColor)" }}>
                        <ReceiptLong />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Add transactions via CSV file
                    </Typography>
                    <Button
                        fullWidth
                        sx={{ my: 1, ...sec_button }}
                        href="https://firebasestorage.googleapis.com/v0/b/apps-static-storage.firebasestorage.app/o/CHAINZ%2Ftransactions_list_sample.csv?alt=media&token=6b660492-9bb3-4963-9e96-4e76f66af76b"
                        target="_blank"
                    >
                        <Download /> CSV Sample
                    </Button>
                    <hr />
                    <MainInput
                        select
                        sx={{ marginBottom: 5 }}
                        defaultValue={source}
                        label="Transaction Source"
                        onChange={({ target }) => setSource((target.value))}
                        fullWidth
                    >
                        <MenuItem value={"Bosta"}>Bosta</MenuItem>
                        <MenuItem value={"Custom"}>Custom</MenuItem>
                    </MainInput>
                    <Box
                        minWidth="100%"
                        component="form"
                        onSubmit={source === "Bosta" ? handleBostaSubmit : handleSubmit}
                        sx={{ mt: 1 }}
                    >
                        <Box minWidth="100%">
                            <ReactFileReader handleFiles={handleFiles} fileTypes={source === "Bosta" ? [".xls", ".xlsx"] : ".csv"}>
                                <Button fullWidth sx={{ my: 1, ...sec_button }}>
                                    <Upload /> Upload {source === "Bosta" ? ".xls or .xlsx" : ".csv"}
                                </Button>
                            </ReactFileReader>
                            {file && <Chip label={file.name} onDelete={handleDeleteFile} />}
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                sx={{ ...main_button }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

const mapStateToProps = (state) => ({
    wait: state?.wait,
});

const mapDispatchToProps = { addTransactions };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddTransactions);
