import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Typography,
  Box,
  Container,
  TextField,
  CssBaseline,
  Avatar,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
  Paper,
  FormControl,
  Tabs,
  Tab
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { connect } from "react-redux";
import {
  Navigate,
  NavLink,
  Route,
  Routes,
  useNavigate,
  useParams
} from "react-router-dom";
import {
  centered_flex_box,
  MainInput,
  MainInputLabel,
  MainPassword,
  main_button,
  StyledInput,
  MainPhone,
  sec_button,
  left_flex_box
} from "../../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Button, Space, Table, Tag } from "antd";
import Logo from "../../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import ImageComponent from "../../app/components/ImageComponent";
import { editOrders, getOrders, getWarehouses } from "../../app/store/actions/imsAdminActions";
import { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import moment from "moment/moment";
import OrdersTable from "../../app/components/OrdersTable";
import axios from "axios";
import { join } from "lodash";
import download from "downloadjs";

export const PackingSystem = ({
  getOrders,
  editOrders,
  user,
  orders,
  warehouseId,
  isLoading
}) => {
  const navigate = useNavigate();
  const [selectedOrders, setSelectedOrders] = React.useState([]);
  const [gettingAWB, setGettingAWB] = React.useState(false);
  const [action, setAction] = React.useState(false);
  const currentLocale = Cookies.get("i18next") || "en";
  const { t, i18n } = useTranslation();

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
    },
    {
      title: 'Shopify Number',
      dataIndex: 'shopifyOrderNumber',
      filters: [
        ...new Map(
          orders?.docs
            ?.filter((order) => order.shopifyOrderNumber !== null).map((order) => [
              order.shopifyOrderNumber,
              { text: order.shopifyOrderNumber, value: order.shopifyOrderNumber },
            ])
        ).values(),
      ],
      filterSearch: true,
      onFilter: (value, record) => record.shopifyOrderNumber?.startsWith(value),
    },
    {
      title: 'Tracking ID',
      dataIndex: 'trackingId',
    },
    {
      title: 'Date Ordered',
      dataIndex: 'date',
      sorter: (a, b) => { return moment(a.date).isBefore(b.date) ? -1 : 1 },
    },
    {
      title: 'Date Assigned',
      dataIndex: 'dateAssigned',
      sorter: (a, b) => { return moment(a.dateAssigned).isBefore(b.dateAssigned) ? -1 : 1 },
    },
    {
      title: 'Date Packed',
      dataIndex: 'dateFulfilled',
      sorter: (a, b) => { return moment(a.dateFulfilled).isBefore(b.dateFulfilled) ? -1 : 1 },
    },
    {
      title: 'Assigned Employee',
      dataIndex: 'assignee'
    },
    {
      title: 'Time to pack',
      dataIndex: 'time'
    },
    {
      title: 'Customer Name',
      dataIndex: 'customer',
      filters: orders?.docs?.filter((order) => order.orderFulfillment !== null).map((order) => {
        return {
          text: order.orderFulfillment?.customer,
          value: order.orderFulfillment?.customer
        }
      }),
      filterSearch: true,
      onFilter: (value, record) => record.customer?.startsWith(value),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: [
        {
          text: "New",
          value: "New"
        },
        {
          text: "Preparing",
          value: "Preparing"
        },
        {
          text: "Prepared",
          value: "Prepared"
        },
        {
          text: "Ready for Pickup",
          value: "Ready for Pickup"
        }
      ],
      onFilter: (value, record) => record.status === value,
      render: (_, { status }) => <Tag color={status === "Delivered" ? "green" : status === "Cancelled" ? "red" : status === "Prepared" ? "blue" : "yellow"}>{status.toUpperCase()}</Tag>
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      filters: [
        ...new Map(
          orders?.docs
            ?.filter((order) => order.vendor !== null)
            .map((order) => [
              order.vendor?.name, // Use vendor's name as the key
              { text: order.vendor?.name, value: order.vendor?.name },
            ])
        ).values(),
      ],
      filterSearch: true,
      onFilter: (value, record) => record.vendor.startsWith(value),
    },
    {
      title: 'Actions',
      render: (_, { status, _id }) => <Space>
        {status === "Prepared" && <Button onClick={() => verifyOrderPacked(_id)}>Verify Packed Order</Button>}
      </Space>
    },
  ];

  const data = orders?.docs?.map((row) => {
    return {
      _id: row._id,
      shopifyOrderNumber: row.shopifyOrderNumber,
      key: row.shipbluTrackingId || row.bostaTrackingId || row._id,
      trackingId: row.shipbluTrackingId || row.bostaTrackingId,
      date: moment(row.createdAt).format("lll"),
      dateFulfilled: row.preparedAt ? moment(row.preparedAt).format("lll") : null,
      dateAssigned: row.assignedAt ? moment(row.assignedAt).format("lll") : null,
      assignee: row.assignee ? row.assignee.name : null,
      time: row.preparedAt && row.assignedAt ? moment(moment(row.preparedAt).diff(moment(row.assignedAt), "seconds") * 1000).format("mm:ss") : null,
      status: row.status,
      customer: row.orderFulfillment?.customer,
      vendor: row.vendor.name,
      orderItems: row.orderItems,
    }
  });

  const expandedRowRender = (items) => {
    console.log(items)
    const columns = [
      {
        title: 'Product ID',
        dataIndex: 'inboundId',
      },
      {
        title: 'Image',
        dataIndex: 'image',
        render: (text) => <ImageComponent width={50} height={50} src={text} preview={true} />,
      },
      {
        title: 'Name',
        dataIndex: 'name'
      },
      {
        title: 'Size',
        dataIndex: 'size',
      },
      {
        title: 'Color',
        dataIndex: 'color',
      },
      {
        title: 'Weight',
        dataIndex: 'weight',
      },
      {
        title: 'QTY Ordered',
        dataIndex: 'quantity',
      },
    ];

    const data = items?.map((row) => {
      return {
        _id: row._id,
        inboundId: row._id,
        image: row.product.image,
        name: row.product.name,
        size: row.product.size,
        color: row.product.color,
        weight: row.product.weight,
        quantity: row.quantityOrdered,
      }
    })
    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedOrders(newSelectedRowKeys.map((key) => { return key }));
  };

  const rowSelection = {
    selectedOrders,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.status !== "New",
    }),
  };

  const hasSelected = selectedOrders.length > 0;

  const handlePrintAWBs = () => {
    const shipbluOrders = selectedOrders.filter((trackingId) => {
      return orders?.docs?.find((order) => { return order.shipbluTrackingId === trackingId }) !== undefined
    })
    const bostaOrders = selectedOrders.filter((trackingId) => {
      return orders?.docs?.find((order) => { return order.bostaTrackingId === trackingId }) !== undefined
    })

    if (shipbluOrders.length > 0) {
      setGettingAWB(true);
      axios.get(`${process.env.REACT_APP_SHIPBLU_ENDPOINT}/v1/orders/shipping-label/`, {
        params: {
          "type": "pdf",
          "tracking_numbers": `${join(shipbluOrders, ",")}`
        },
        headers: {
          Authorization: process.env.REACT_APP_SHIPBLU_KEY
        },
        responseType: "arraybuffer"
      }).then(({ data }) => {
        download(data, "Shipblu Orders AWBs.pdf", 'application/pdf')
      }).finally(() => {
        setGettingAWB(false);
      })
    }

    if (bostaOrders.length > 0) {
      setGettingAWB(true);
      axios.post(`${process.env.REACT_APP_BOSTA_ENDPOINT}/v2/deliveries/mass-awb`,
        {
          "trackingNumbers": `${join(bostaOrders, ",")}`,
          "requestedAwbType": "A4",
          "lang": "ar"
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_BOSTA_KEY
          }
        }).then(({ data }) => {
          // Decode the Base64 string
          const byteCharacters = atob(data.data);
          const byteNumbers = new Uint8Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          // Create a Blob from the byte array
          const blob = new Blob([byteNumbers], { type: 'application/pdf' });

          // Use downloadjs to download the Blob
          download(blob, "Bosta Orders AWBs.pdf", 'application/pdf')
        }).finally(() => {
          setGettingAWB(false);
        })
    }
  }

  const verifyOrderPacked = (orderId) => {
    editOrders({ orderId, edits: { status: "Ready for Pickup" } }, setAction, action)
  }

  useEffect(() => {
    getOrders({ page: 1, limit: 500, isReturn: false, populateItems: true, status: ["New", "Preparing", "Prepared", "Ready for Pickup"] })
  }, [action])

  return (
    <div>
      <Box sx={{ width: "100%", padding: 5, mt: 2 }}>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Preparing Orders</Typography>
          {/* <IconButton onClick={() => navigate("/ims/admin/orders/add")} sx={{ color: "var(--secColor)", mx: 2 }}><AddCircleOutline /></IconButton> */}
        </Box>
        <Box sx={{ ...left_flex_box }}>
          <Button onClick={handlePrintAWBs} type="primary" disabled={!hasSelected} loading={isLoading || gettingAWB}>
            Print AWBs
          </Button>
          <Typography sx={{ ml: 1 }}>{hasSelected ? `Selected ${selectedOrders.length} orders` : null}</Typography>
        </Box>
        <Table
          columns={columns}
          dataSource={data}
          showSorterTooltip={{
            target: 'sorter-icon',
          }}
          expandable={{
            expandedRowRender: (record) => expandedRowRender(record.orderItems)
          }}
          rowSelection={rowSelection}
        />
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  orders: state?.records?.orders,
});

const mapDispatchToProps = { getOrders, editOrders };

export default connect(mapStateToProps, mapDispatchToProps)(PackingSystem);
