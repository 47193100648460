import * as React from "react";
import {
    Typography,
    Box,
    IconButton,
    Grid,
    Button as MUIButton
} from "@mui/material";
import { connect } from "react-redux";
import {
    NavLink,
    Navigate,
    useNavigate,
} from "react-router-dom";
import {
    MainInput,
    centered_flex_box,
    left_flex_box,
    main_button,
} from "../../app/components/Styles";
import { Button, Image, Space, Table, Tag } from "antd";
import { getUnpaidOrders } from "../../app/store/actions/whFinanceActions";
import { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import moment from "moment";

export const UnpaidOrders = ({
    getUnpaidOrders,
    orders,
    isLoading
}) => {
    const navigate = useNavigate();

    useEffect(() => {
        getUnpaidOrders({})
    }, [])

    const columns = [
        {
            title: 'Order ID',
            dataIndex: '_id',
        },
        {
            title: 'Order Tracking ID',
            dataIndex: 'trackingId',
        },
        {
            title: 'Status',
            dataIndex: ['order', 'status'],
            filters: [
                {
                    text: "Returned",
                    value: "Returned"
                },
                {
                    text: "Delivered",
                    value: "Delivered"
                }
            ],
            onFilter: (value, record) => record.status === value,
            render: (_, { order }) => <Tag color={order.status === "Delivered" ? "green" : order.status === "Cancelled" ? "red" : order.status === "Prepared" ? "blue" : "yellow"}>{order.status.toUpperCase()}</Tag>
        },
        {
            title: 'Order Price',
            dataIndex: ['order', 'price'],
            render: (_, { order }) => `${order.price} EGP`,
            sorter: (a, b) => { return a.order.price - b.order.price },
        },
        {
            title: 'Vendor',
            dataIndex: ['order', 'vendor', 'name'],
            filters: [
                ...new Map(
                    orders
                        ?.filter((order) => order.vendor !== null)
                        .map((order) => [
                            order.vendor?.name, // Use the vendor's name as the key
                            { text: order.vendor?.name, value: order.vendor?.name },
                        ])
                ).values(),
            ],
            filterSearch: true,
            onFilter: (value, record) => record.vendor.name.startsWith(value),
        },
    ];

    const data = orders?.map((row) => {
        return {
            _id: row._id,
            order: row,
            trackingId: row.shipbluTrackingId || row.bostaTrackingId
        }
    })

    return (
        <div>
            <Box sx={{ width: "100%", padding: 5, mt: 2 }}>
                <Box sx={{ ...centered_flex_box, mb: 1 }}>
                    <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Unpaid Orders</Typography>
                </Box>
                <Table
                    columns={columns}
                    dataSource={data}
                    loading={isLoading}
                    showSorterTooltip={{
                        target: 'sorter-icon',
                    }}
                    rowKey={"_id"}
                />
            </Box>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading || state?.wait?.isLoading,
    token: state?.auth?.token,
    orders: state?.records?.orders,
});

const mapDispatchToProps = { getUnpaidOrders };

export default connect(mapStateToProps, mapDispatchToProps)(UnpaidOrders);
