import * as React from "react";
import {
    Typography,
    Box,
    IconButton,
    Grid,
    Button as MUIButton
} from "@mui/material";
import { connect } from "react-redux";
import {
    NavLink,
    Navigate,
    useNavigate,
} from "react-router-dom";
import {
    MainInput,
    centered_flex_box,
    left_flex_box,
    main_button,
} from "../../app/components/Styles";
import { Button, Image, Space, Table, Tag } from "antd";
import { getTransactions } from "../../app/store/actions/whFinanceActions";
import { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import moment from "moment";

export const Transactions = ({
    getTransactions,
    transactions,
    isLoading
}) => {
    const navigate = useNavigate();
    const [startDate, setStartDate] = React.useState(moment().startOf("month"))
    const [endDate, setEndDate] = React.useState(moment().endOf("month"))

    useEffect(() => {
        getTransactions({ startDate, endDate })
    }, [])

    const handleFilter = () => {
        getTransactions({ startDate, endDate });
    }

    const columns = [
        {
            title: 'Order ID',
            dataIndex: '_id',
        },
        {
            title: 'Order Tracking ID',
            dataIndex: 'trackingId',
        },
        {
            title: 'Status',
            dataIndex: ['order', 'status'],
            filters: [
                {
                    text: "Returned",
                    value: "Returned"
                },
                {
                    text: "Delivered",
                    value: "Delivered"
                }
            ],
            onFilter: (value, record) => record.order.status === value,
            render: (_, { order }) => <Tag color={order.status === "Delivered" ? "green" : order.status === "Cancelled" ? "red" : order.status === "Prepared" ? "blue" : "yellow"}>{order.status.toUpperCase()}</Tag>
        },
        {
            title: 'Vendor',
            dataIndex: ['order', 'vendor', 'name'],
            filters: [
                ...new Map(
                    transactions
                        ?.filter(({order}) => order.vendor !== null)
                        .map(({order}) => [
                            order.vendor?.name, // Use the vendor's name as the key
                            { text: order.vendor?.name, value: order.vendor?.name },
                        ])
                ).values(),
            ],
            filterSearch: true,
            onFilter: (value, record) => record.order.vendor.name.startsWith(value),
        },
    ];

    const data = transactions?.map((row) => {
        return {
            _id: row.order._id,
            order: row.order,
            trackingId: row.order.shipbluTrackingId || row.order.bostaTrackingId,
            transactions: row.transactions,
        }
    })

    const expandedRowRender = (items) => {
        const columns = [
            {
                title: 'Date Created',
                dataIndex: 'date',
                sorter: (a, b) => { return moment(a.date).isBefore(b.date) ? -1 : 1 },
            },
            {
                title: 'Value',
                dataIndex: 'value',
                render: (_, { value }) => `${value} EGP`,
                sorter: (a, b) => { return a.value - b.value },
            },
            {
                title: 'Type',
                dataIndex: 'type',
                filters: [
                    {
                        text: "COD",
                        value: "COD"
                    },
                    {
                        text: "Service Fee",
                        value: "Fee"
                    },
                    {
                        text: "Shipping Fee",
                        value: "ShippingFee"
                    },
                    {
                        text: "Hold",
                        value: "Hold"
                    },
                    {
                        text: "Payout",
                        value: "Payout"
                    }
                ],
                onFilter: (value, record) => record.type === value,
                render: (_, { type }) => <Tag>{type}</Tag>
            },
            {
                title: 'Service',
                dataIndex: 'service',
                filters: [
                    {
                        text: "RTO",
                        value: "RTO"
                    },
                    {
                        text: "RTN",
                        value: "RTN"
                    },
                    {
                        text: "EXG",
                        value: "EXG"
                    },
                    {
                        text: "DLV",
                        value: "DLV"
                    },
                    {
                        text: "TRN",
                        value: "TRN"
                    },
                    {
                        text: "NONE",
                        value: "NONE"
                    }
                ],
                onFilter: (value, record) => record.service === value,
                render: (_, { service }) => <Tag>{service}</Tag>
            },
        ];

        const data = items?.map((row) => {
            return {
                _id: row._id,
                date: moment(row.createdAt).format("lll"),
                value: row.value,
                type: row.type,
                service: row.service,
            }
        })
        return <Table columns={columns} dataSource={data} pagination={false} />;
    };

    return (
        <div>
            <Box sx={{ width: "100%", padding: 5, mt: 2 }}>
                <Box sx={{ ...centered_flex_box, mb: 1 }}>
                    <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Transactions</Typography>
                    <IconButton onClick={() => navigate("/warehousing/finance/transactions/add")} sx={{ color: "var(--secColor)", mx: 2 }}><AddCircleOutline /></IconButton>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", px: 5 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <Typography>From</Typography>
                            <MainInput
                                margin="normal"
                                fullWidth
                                type="date"
                                value={startDate.format("YYYY-MM-DD")}
                                onChange={({ target }) => setStartDate(moment(target.value))}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <Typography>To</Typography>
                            <MainInput
                                margin="normal"
                                fullWidth
                                type="date"
                                value={endDate.format("YYYY-MM-DD")}
                                onChange={({ target }) => setEndDate(moment(target.value))}
                            />
                        </Grid>
                        <Grid item xs={2} sx={{ ...left_flex_box, alignItems: "end" }}>
                            <MUIButton sx={{ ...main_button, mb: 2 }} onClick={() => handleFilter()}>Filter</MUIButton>
                        </Grid>
                    </Grid>
                </Box>
                <Table
                    columns={columns}
                    dataSource={data}
                    loading={isLoading}
                    showSorterTooltip={{
                        target: 'sorter-icon',
                    }}
                    rowKey={"_id"}
                    expandable={{
                        expandedRowRender: (record) => expandedRowRender(record.transactions)
                    }}
                />
            </Box>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading || state?.wait?.isLoading,
    token: state?.auth?.token,
    transactions: state?.records?.transactions,
    payouts: state?.records?.payouts
});

const mapDispatchToProps = { getTransactions };

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
